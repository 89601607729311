import React, {useState} from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });

function Slider(){
    const { t } = useTranslation();
    return(
<div>
<link rel="preload" href="../img/banner/banner.png" as="image"></link>
<div class="slider_area">
<div class="single_slider  d-flex align-items-center slider_bg_1">
<div class="container">
<div class="row align-items-center justify-content-center">
<div class="col-xl-8">

<div class="slider_text text-center justify-content-center">

<p datal-i18n="title1">{t('sTitle1')}</p>
<h3 datal-i18n="title2">INTERNATIONAL TRANSPORT<br/> TRANSIT & SERVICES</h3>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
    
     )

}
export default Slider;