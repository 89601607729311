import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    detection: {
      order: ['htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
      caches: ["cookie"],
    },
    backend: {
      loadPath: '/locale/{{lng}}/translation.json',
    }
  });

export const Footer = () => {
  const form = useRef();
  const [message, setMessage] = useState(''); // état pour le message
  const [loading, setLoading] = useState(false); // état pour savoir si l'envoi est en cours
  const { t } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); // active le chargement lors de l'envoi
    setMessage(''); // réinitialise le message précédent

    emailjs
      .sendForm('service_wvartf9', 'template_2lzes6g', form.current, {
        publicKey: 'z3-DYx1t4JK77EFyH',
      })
      .then(
        (result) => {
          console.log(result.text);
          console.log('SUCCESS!');
          setLoading(false);
          setMessage(t('Subscription successful!')); // Message de succès
          form.current.reset(); // réinitialise le formulaire après un envoi réussi
        },
        (error) => {
          console.log('FAILED...', error.text);
          setLoading(false);
          setMessage(t('Something went wrong, please try again later.')); // Message d'erreur
        }
      );
  };

  return (
    <div>
      <footer className="footer">
        <div className="footer_top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-lg-3">
                <div className="footer_widget">
                  <h3 className="footer_title">{t('Services')}</h3>
                  <ul>
                    <li><a href="/services?service=fret-maritime" data-i18n="titleservice1">{t('Fret maritime')}</a></li>
                    <li><a href="/services?service=fret-aerien" data-i18n="titleservice2">{t('Fret Aerien')}</a></li>
                    <li><a href="/services?service=terrestre" data-i18n="titleservice3">{t('Terrestre')}</a></li>
                    <li><a href="/services?service=douane" data-i18n="titleservice4">{t('Douane')}</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-2 col-md-6 col-lg-2">
                <div className="footer_widget">
                  <h3 className="footer_title">{t('Entreprise')}</h3>
                  <ul>
                    <li><a href="/about" data-i18n="about">{t('About')}</a></li>
                    <li><a href="/contact" data-i18n="contact">{t('Contact')}</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-6 col-md-6 col-lg-6">
                <div className="footer_widget">
                  <h3 className="footer_title" data-i18n="btnabonner">{t('Subscribe')}</h3>
                  <form ref={form} onSubmit={sendEmail} className="newsletter_form">
                    <input name="from_email" type="email" placeholder={t('Enter your mail')} required />
                    <button type="submit" disabled={loading}>
                      {loading ? t('Sending...') : t('Subscribe')}
                    </button>
                  </form>
                  {message && <p className="newsletter_message">{message}</p>}
                  <p className="newsletter_text" data-i18n="textfooter">{t('textfooter')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right_text">
          <div className="container">
            <div className="footer_border"></div>
            <div className="row">
              <div className="col-xl-12">
                <p className="copy_right text-center"></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
